import { graphql, useStaticQuery } from "gatsby";

export const ListData = () => {

  const data = useStaticQuery(graphql`
    query {
      allMdx(limit: 2000) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
    `)

  return data;
}

