import React from 'react'
import { graphql } from 'gatsby'

import { TagLink } from "../components/animated/index";
import {
  Container,
  Layout,
  ListingItem,
} from "../components/page";
import { SEO } from "../components/metadata/index";
import { ListData } from "../hooks/tags-data";

const TaggedPosts = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMdx
  const tagHeader = `${totalCount} post${totalCount === 1 ? '' : 's'}`

  const siteTitle = data.site.siteMetadata.title
  const tags = ListData().allMdx.group

  return (
    <Layout title={siteTitle}>
      <SEO title="/tagged-posts" />
      <Container>
        <section className="border-2 border-gray border-dashed rounded mt-4 mb-8 max-w-3xl mx-auto p-2 ">
          <h1 className="text-xl">Tags</h1>
          {Object.entries(tags).map(tag => {
            let tagText = tag[1].fieldValue;
            return (
              <TagLink
                tagText={tagText}
                key={tagText}
              >{tagText}</TagLink>
            )
          })}
        </section>
        <div className="flex justify-between items-baseline">
          <h2 className="mb-2 text-3xl">{tag}</h2>
          <span className="mb-2">{tagHeader}</span>
        </div>
        <div>
          {edges.map(({ node }) => {
            return (
              <ListingItem key={node.slug} node={node} />
            );
          })}
        </div>
      </Container>
    </Layout>
  )
}

export default TaggedPosts

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { tags: { in: [$tag] } } 
      }
    ) {
      totalCount
      edges {
        node {
          slug
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
